import { useLazyQuery } from '@apollo/react-hooks';
import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import gql from 'graphql-tag';
import Router from 'next/router';
import { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmail } from 'validator';

import { SignInResult } from '@app-lib/authentication/hooks';
import { SignInFormValues } from '@app-lib/authentication/typings';
import Button from '@legacy-components/Button';
import Feedback, { Level } from '@legacy-components/Feedback';
import PasswordInput from '@legacy-components/PasswordInput';
import TextInput from '@legacy-components/TextInput';
import { Color } from '@legacy-components/theme';

import Form from '../../../common/components/Form';
import LeftAlign from '../../../common/components/LeftAlign';
import Link from '../../../common/components/Link';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { buildFormikBridgeProps } from '../../../core/helper/form';
import { Routes } from '../../../typings';
import Layout from '../../components/Layout';
import { MobileRedirectType } from '../MobileAppRedirect/helper';
import { handleSignIn } from './helper';

export interface Props extends Omit<SignInResult, 'loading'> {
  initialValues?: SignInFormValues;
  signInOrSignUpMode?: boolean;
}

interface LoginType {
  type: string;
  url?: string;
}

export const LOGIN_TYPE_QUERY = gql`
  query LOGIN_TYPE($email: String) {
    loginType(email: $email)
      @rest(type: "LoginType", path: "/auth/login-type/?email={args.email}") {
      type
      url
    }
  }
`;

function validateSignInForm(
  values: SignInFormValues,
  passwordLogin: boolean,
): FormikErrors<SignInFormValues> {
  const errors: FormikErrors<SignInFormValues> = {};

  if (!isEmail(values.email)) {
    errors.email = 'Invalid email';
  }

  if (passwordLogin && !values.password) {
    errors.password = 'Empty password';
  }

  return errors;
}

const SignIn = ({
  signIn,
  inError,
  invalidCredentials,
  initialValues,
  signInOrSignUpMode = false,
}: Props): ReactElement => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const [getLoginType, loginTypeResult] = useLazyQuery<{
    loginType: LoginType;
  }>(LOGIN_TYPE_QUERY);
  const passwordLogin = loginTypeResult.data?.loginType?.type === 'password';

  useEffect(() => {
    if (loginTypeResult.data?.loginType?.type === 'saml') {
      window.open(loginTypeResult.data.loginType.url, '_self');
    }
  }, [loginTypeResult.data]);
  return (
    <Layout
      withLogo
      title={
        <FormattedMessage
          id="authentication.signIn.title"
          defaultMessage="Back in the canteen of the future"
        />
      }
      rightButton={
        <Button
          testID="subscribeButton"
          title={intl.formatMessage({
            id: 'authentication.signIn.subscribeButton',
            defaultMessage: 'Subscribe',
          })}
          color={Color.QUATERNARY_LIGHT}
          onPress={(): Promise<boolean> =>
            Router.push(
              signInOrSignUpMode
                ? {
                    pathname: Routes.SIGNUP,
                    query: {
                      signInOrSignUpMode: true,
                    },
                  }
                : {
                    pathname: Routes.MOBILE_APP_REDIRECT,
                    query: {
                      mobileRedirectType: MobileRedirectType.NO_ACCOUNT,
                    },
                  },
            )
          }
          small
        />
      }
    >
      <Formik
        initialValues={{ email: '', password: '', ...initialValues }}
        validate={(values: SignInFormValues): FormikErrors<SignInFormValues> =>
          validateSignInForm(values, passwordLogin)
        }
        onSubmit={async (
          { email, password }: SignInFormValues,
          formikHelpers: FormikHelpers<SignInFormValues>,
        ): Promise<void> => {
          setLoading(true);
          if (!loginTypeResult.data) {
            getLoginType({ variables: { email } });
          } else {
            await handleSignIn({ signIn, email, password });
          }
          formikHelpers.setSubmitting(false);
          setLoading(false);
        }}
      >
        {(formikBag: FormikProps<SignInFormValues>): ReactElement => {
          const submitDisabled =
            !formikBag.isValid || !formikBag.dirty || formikBag.isSubmitting;

          return (
            <Form onSubmit={formikBag.handleSubmit}>
              {inError && (
                <Feedback level={Level.ERROR}>
                  <FormattedMessage
                    id="authentication.signIn.error.unexpected"
                    defaultMessage="An unexpected error occurred - please try again later. If the problem persists send an email to {supportEmail}."
                    values={{
                      supportEmail: (
                        <Link
                          color={Color.DANGER}
                          href={`mailto:${intl.formatMessage({
                            id: 'common.email.support',
                            defaultMessage: 'support@foodles.co',
                          })}`}
                        >
                          <FormattedMessage
                            id="common.email.support"
                            defaultMessage="support@foodles.co"
                          />
                        </Link>
                      ),
                    }}
                  />
                </Feedback>
              )}
              {invalidCredentials && (
                <Feedback level={Level.ERROR}>
                  <FormattedMessage
                    id="authentication.signIn.error.invalidCredentials"
                    defaultMessage="Invalid username and/or password"
                  />
                </Feedback>
              )}

              <TextInput
                {...buildFormikBridgeProps(formikBag, 'email')}
                floatingPlaceholder
                placeholder={intl.formatMessage({
                  id: 'authentication.signIn.email.label',
                  defaultMessage: 'Professional email',
                })}
              />
              {passwordLogin && (
                <PasswordInput
                  {...buildFormikBridgeProps(formikBag, 'password')}
                  floatingPlaceholder
                  placeholder={intl.formatMessage({
                    id: 'authentication.signIn.password.label',
                    defaultMessage: 'Password',
                  })}
                />
              )}
              <LeftAlign>
                <Link
                  testID="forgottenPasswordLink"
                  onClick={(): Promise<boolean> =>
                    Router.push(Routes.REQUEST_PASSWORD_RESET)
                  }
                >
                  <FormattedMessage
                    id="authentication.signIn.forgottenPassword"
                    defaultMessage="Forgot your password?"
                  />
                </Link>
              </LeftAlign>

              <input type="submit" hidden disabled={submitDisabled} />

              {!loading && (
                <Button
                  testID="submitButton"
                  fullWidth
                  onPress={formikBag.submitForm}
                  accessibilityRole="button"
                  color={Color.PRIMARY}
                  disabled={submitDisabled}
                  title={
                    passwordLogin
                      ? intl.formatMessage({
                          id: 'authentication.signIn.submitButton',
                          defaultMessage: 'Sign in',
                        })
                      : intl.formatMessage({
                          id: 'authentication.signIn.continueButton',
                          defaultMessage: 'Continue',
                        })
                  }
                />
              )}
              {loading && <LoadingSpinner />}
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default SignIn;
